import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import cookie from 'cookie';
import { IncomingMessage } from 'http';
import jsCookie from 'js-cookie';
import getConfig from 'next/config';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import qs from 'qs';
import { BEARER_PREFIX } from 'utils/constants';
import { getApiUrl, getAuthUrl } from 'utils/global';
import { LoginResponse, TokenSignUpResponse } from './api.types';

export type NextServerRequestObject = IncomingMessage & {
  cookies: NextApiRequestCookies;
};

interface NextApiRequestOptions {
  /**
   * The request object from the next.js context
   */
  requestServer?: NextServerRequestObject;
}

/**
 * Get the api token for the current user
 * If no logged in user return the default token
 * When used server side, the request object from the next context should be passed in as argument to get the cookies from there
 */

interface GetApiTokenOptions extends NextApiRequestOptions {
  /**
   * If true, this will return the default token regardless the current user
   */
  forceDefault?: boolean;
  /**
   * If false, this will trow an error if there is no logged in user
   * It's false by default
   */
  allowDefault?: boolean;
}

export const getApiToken = ({
  forceDefault = false,
  allowDefault = false,
  requestServer,
}: GetApiTokenOptions = {}): string => {
  if (forceDefault) {
    return getConfig().publicRuntimeConfig.API_TOKEN;
  }
  if (requestServer && requestServer.headers.cookie) {
    const cookies = cookie.parse(requestServer.headers.cookie);
    const token = cookies.token;
    if (!token && !allowDefault) {
      throw new Error('No token!');
    }
    return token ?? getConfig().publicRuntimeConfig.API_TOKEN;

    // Make sure we are on client sided
  } else if (typeof window !== 'undefined') {
    const token = jsCookie.get('token');
    if (!token && !localStorage.getItem('quizAccessToken') && !allowDefault) {
      throw new Error('No token!');
    }
    return (
      token ??
      localStorage.getItem('quizAccessToken') ??
      getConfig().publicRuntimeConfig.API_TOKEN
    );
  }
  return getConfig().publicRuntimeConfig.API_TOKEN;
};

export const client = axios.create({
  baseURL: getApiUrl(),
  headers: {
    Authorization: BEARER_PREFIX + getApiToken({ allowDefault: true }),
    'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
  },
});

// We use axios interceptors to process the response of every request.
// If the response doesn't throw an error, let it pass.
// Otherwise, if SI API requests throw an error, try to refresh the auth token.
client.interceptors.response.use(
  response => {
    return response;
  },
  // async error => {
  // const originalRequest = error.config;
  // try {
  //   if (
  //     // 401 request failures result in token refresh attempts
  //     error.response.status === 401 &&
  //     !originalRequest._retry &&
  //     originalRequest.baseURL === 'https://api.deepstash.com/v2/' &&
  //     typeof window !== 'undefined'
  //   ) {
  //     originalRequest._retry = true;
  //     const refreshToken = jsCookie.get('refresh_token');
  //     if (refreshToken) {
  //       const response = await AuthService.refreshAuthToken({ refreshToken });
  //       if (response && response.access_token) {
  //         jsCookie.set('token', response.access_token, {
  //           expires: DEFAULT_COOKIE_EXPIRATION_DATE,
  //           sameSite: 'lax',
  //           secure:
  //             process.env.NODE_ENV === 'production' &&
  //             window.location.hostname !== 'localhost',
  //         });
  //         jsCookie.set('refresh_token', response.refresh_token, {
  //           expires: DEFAULT_COOKIE_EXPIRATION_DATE + 1,
  //           sameSite: 'lax',
  //           secure:
  //             process.env.NODE_ENV === 'production' &&
  //             window.location.hostname !== 'localhost',
  //         });
  //         client.defaults.headers.common['Authorization'] =
  //           BEARER_PREFIX + response.access_token;
  //       }
  //     }
  //   }
  // } catch (e) {
  //   console.log('[Axios Refresh Token Middleware Error]');
  //   // Refresh request fails
  //   jsCookie.remove('token');
  //   jsCookie.remove('refresh_token');
  //   throw e;
  // }
  // // Request fails
  // throw error;
  // },
);

client.defaults.headers.common['Authorization'] =
  BEARER_PREFIX + getApiToken({ allowDefault: true });

export function onRequestSuccess<T>(response: AxiosResponse<T>) {
  return response.data;
}

export function onRequestError<T>(error: AxiosError<T>) {
  return Promise.reject(error.response || error.message);
}

export async function getRequest<T>(
  url: string,
  token: string,
  config?: AxiosRequestConfig,
): Promise<T> {
  return client
    .get<T, AxiosResponse<T>>(url, {
      ...config,
      headers: {
        Authorization: BEARER_PREFIX + token,
        'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
      },
    })
    .then(onRequestSuccess)
    .catch(onRequestError);
}

export async function postRequest<T, D>(
  url: string,
  token: string,
  data?: D | undefined,
  config?: AxiosRequestConfig | undefined,
  customOnRequestSuccess?: (res: AxiosResponse<T>) => any,
  defaultErrorHandling = true,
): Promise<T> {
  return client
    .post<T, AxiosResponse<T>>(url, data, {
      ...config,
      headers: {
        Authorization: BEARER_PREFIX + token,
        'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
      },
    })
    .then(customOnRequestSuccess ?? onRequestSuccess)
    .catch(error => {
      if (defaultErrorHandling) {
        onRequestError(error);
      }
      {
        throw error;
      }
    });
}

export async function postFormRequest<T, D>(
  url: string,
  token: string,
  data?: D | undefined,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  return client
    .post<T, AxiosResponse<T>>(url, data, {
      ...config,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: BEARER_PREFIX + token,
        'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
      },
    })
    .then(onRequestSuccess)
    .catch(onRequestError);
}

export async function putRequest<T, D>(
  url: string,
  token: string,
  data?: D | undefined,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  return client
    .put<T, AxiosResponse<T>>(url, data, {
      ...config,
      headers: {
        Authorization: BEARER_PREFIX + token,
        'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
      },
    })
    .then(onRequestSuccess)
    .catch(onRequestError);
}

export async function deleteRequest<T>(
  url: string,
  token: string,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  return client
    .delete<T, AxiosResponse<T>>(url, {
      ...config,
      headers: {
        Authorization: BEARER_PREFIX + token,
        'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
      },
    })
    .then(onRequestSuccess)
    .catch(onRequestError);
}

export async function patchRequest<T, D>(
  url: string,
  token: string,
  data?: D | undefined,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  return client
    .patch<T, AxiosResponse<T>>(url, data, {
      ...config,
      headers: {
        Authorization: BEARER_PREFIX + token,
        'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
      },
    })
    .then(onRequestSuccess)
    .catch(onRequestError);
}

export async function socialAuthRequest(
  url: string,
): Promise<TokenSignUpResponse> {
  return client.get(url).then(onRequestSuccess).catch(onRequestError);
}

export async function authRequest(
  username: string,
  password: string,
): Promise<LoginResponse> {
  return client
    .post(
      '/',
      qs.stringify({
        username,
        password,
        grant_type: 'password',
        client_id: process.env.OAUTH_CLIENT_ID,
      }),
      {
        baseURL: getAuthUrl(),
        headers: {
          Authorization: null,
          'content-type': 'application/x-www-form-urlencoded',
          'User-Agent': 'web/' + process.env.WEBSITE_VERSION,
        },
      },
    )
    .then(onRequestSuccess)
    .catch(onRequestError);
}

export interface AddUrlRequestData {
  url: string;
  type: number;
  author?: string;
  image?: string;
  title?: string;
  content?: string;
  description?: string;
}

export interface ImportLinkData {
  url: string;
}

export interface ImportSelfpost {
  description: string;
  title: string;
}

/**
 * Utility function used as the response handler for an Axios request
 * Returns the response data along the response status code
 * @param res The API call response
 */
export function returnDataAndResponseStatus<T>(res: AxiosResponse<T>) {
  return {
    data: res.data,
    code: res.status,
  };
}

export type ApiResponseWithStatusCode<T> = {
  data: T;
  code: number;
};

export function isAxiosError(obj: any): obj is AxiosError {
  return (obj as AxiosError).isAxiosError;
}
