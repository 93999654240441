import React, { createContext, useEffect, useState } from 'react';
import { Analytics } from 'services/analytics';
import { experimentIds, getExperimentUserProp } from 'utils/constants';
import { ExperimentProviderStateType } from './ExperimentProviderTypes';

export const ExperimentContext = createContext({
  variants: {},
} as ExperimentProviderStateType);

ExperimentContext.displayName = 'ExperimentContext';

interface ExperimentProviderProps {
  children: React.ReactNode;
}

const ExperimentProvider: React.FC<ExperimentProviderProps> = ({
  children,
}) => {
  const [variants, setVariants] = useState<Record<string, string>>({});
  useEffect(() => {
    (async () => {
      if (window && (window as any).dataLayer) {
        await (window as any).dataLayer.push({ event: 'optimize.activate' });
      }
      Object.values(experimentIds).map(experimentId => {
        const intervalId = setInterval(() => {
          if ((window as any).google_optimize) {
            Analytics.setIdentifyUserProp({
              propName: getExperimentUserProp(experimentId),
              propValue: `${
                (window as any).google_optimize.get(experimentId) ?? -1
              }`,
            });

            // Add the variant to the state.
            setVariants(currentVariants => {
              return {
                ...currentVariants,
                [experimentId]: (window as any).google_optimize.get(
                  experimentId,
                ),
              };
            });
            clearInterval(intervalId);
          }
        });
      }, 100);
    })();
  }, []);

  return (
    <ExperimentContext.Provider value={{ variants }}>
      {children}
    </ExperimentContext.Provider>
  );
};

export default ExperimentProvider;
