import {
  Flex,
  spacing,
  color,
  LogoSvg,
  PrimaryText,
  PrimaryHeading,
  useIsMobileView,
} from 'deepstash-ui';
import React, { useCallback, useMemo } from 'react';
import {
  getCurrencySymbolByCode,
  getCurrentDateAfterXDays,
} from 'utils/global';
import pageStrings from 'utils/strings/pageStrings';
import { getSingularOrPlural } from 'utils/StringUtils';
import { PaymentCheckoutDetails } from './PaywallCheckoutModal';
import commonStrings from 'utils/strings/commonStrings';
import { SubscriptionType } from 'types/types';

interface PaywallCheckoutLeftBannerProps {
  paymentDetails: PaymentCheckoutDetails;
  disclaimer?: string;
}

const PaywallCheckoutLeftBanner: React.FC<PaywallCheckoutLeftBannerProps> = ({
  paymentDetails,
  disclaimer,
}) => {
  const isMobileView = useIsMobileView();
  const { priceAmount, freeTrial, subscriptionOrProductType, priceCurrency } =
    paymentDetails;
  const currencySymbol = getCurrencySymbolByCode(priceCurrency);

  const trialEndDate = useMemo(
    () =>
      getCurrentDateAfterXDays(freeTrial?.duration).toLocaleDateString(
        'en-US',
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        },
      ),
    [freeTrial?.duration],
  );

  const getGenericDisclaimer = useCallback(() => {
    if (subscriptionOrProductType === 'LIFETIME') {
      return (
        pageStrings['quiz-funnel'].disclaimers['LIFETIME'][0] +
        getCurrencySymbolByCode(paymentDetails.priceCurrency) +
        paymentDetails.priceAmount +
        pageStrings['quiz-funnel'].disclaimers['LIFETIME'][1]
      );
    } else if (paymentDetails.freeTrial) {
      const subscriptionType = paymentDetails.subscriptionOrProductType;
      return (
        pageStrings['quiz-funnel'].trial_disclaimers[subscriptionType][0] +
        getCurrencySymbolByCode(paymentDetails.priceCurrency) +
        paymentDetails.renewalPriceAmount +
        pageStrings['quiz-funnel'].trial_disclaimers[subscriptionType][1]
      );
    } else {
      const subscriptionType = paymentDetails.subscriptionOrProductType;
      return (
        pageStrings['quiz-funnel'].disclaimers[subscriptionType][0] +
        getCurrencySymbolByCode(paymentDetails.priceCurrency) +
        paymentDetails.renewalPriceAmount +
        pageStrings['quiz-funnel'].disclaimers[subscriptionType][1]
      );
    }
  }, [paymentDetails]);

  return (
    <Flex
      width={{ base: 'calc(100% - 2rem)', lg: spacing.toRem(280) }}
      borderTopEndRadius={spacing.M.rem}
      borderTopStartRadius={{ base: spacing.M.rem, lg: 'unset' }}
      borderBottomStartRadius={spacing.M.rem}
      borderBottomEndRadius={{ base: spacing.M.rem, lg: 'unset' }}
      bgColor={color.light.background}
      py={{ base: spacing.M.rem, lg: spacing.XXXL.rem }}
      pl={{ base: spacing.M.rem, lg: spacing.XXXL.rem }}
      pr={spacing.M.rem}
      mx={{ base: spacing.M.rem, lg: 'unset' }}
      mb={{ base: spacing.M.rem, lg: 'unset' }}
      flexDir="column"
    >
      <Flex align="center">
        <Flex
          p={spacing.XXS.rem}
          borderRadius={spacing.XXS.rem}
          bgColor={color.black}
          mr={spacing.XS.rem}
        >
          <LogoSvg boxSize={spacing.toRem(16)} />
        </Flex>
        <PrimaryText size="s" type="bold" color={color.light.text}>
          {freeTrial !== undefined
            ? pageStrings.paywall.tryDeepstashPro
            : pageStrings.paywall.getDeepstashPro}
        </PrimaryText>
      </Flex>
      {freeTrial !== undefined && subscriptionOrProductType !== 'LIFETIME' ? (
        <>
          <Flex
            flexDir={{ base: 'row', lg: 'column' }}
            alignItems={{ base: 'center', lg: 'unset' }}
            justifyContent={{ base: 'space-between', lg: 'unset' }}
            mt={spacing.M.rem}
          >
            <PrimaryHeading
              textSize={isMobileView ? 's' : 'm'}
              size="h4"
              whiteSpace={'nowrap'}
              type="extraBold"
              color={color.light.pro.primary}
            >
              {freeTrial.duration}{' '}
              {getSingularOrPlural(
                freeTrial.duration,
                freeTrial.periodUnit.toLowerCase(),
                freeTrial.periodUnit.toLowerCase() + 's',
              )}{' '}
              free
            </PrimaryHeading>
            <PrimaryText
              size="m"
              whiteSpace={'nowrap'}
              type={isMobileView ? 'bold' : 'regular'}
              color={color.light.text}
            >
              Then {currencySymbol}
              {priceAmount}
              {
                commonStrings.paywall.perPeriod[
                  subscriptionOrProductType as SubscriptionType
                ]
              }
            </PrimaryText>
          </Flex>
          {!isMobileView && (
            <Flex mt={spacing.M.rem}>
              <PrimaryText color={color.light.text}>
                After your trial ends, you will be charged {currencySymbol}
                {priceAmount} per {subscriptionOrProductType.toLowerCase()}{' '}
                starting {trialEndDate} . You can always cancel before then.
              </PrimaryText>
            </Flex>
          )}
        </>
      ) : (
        <>
          <PrimaryText
            size="m"
            mt={spacing.M.rem}
            whiteSpace={'nowrap'}
            type={isMobileView ? 'bold' : 'regular'}
            color={color.light.text}
          >
            For {currencySymbol}
            {priceAmount}
            {commonStrings.paywall.perPeriod[subscriptionOrProductType]}
          </PrimaryText>
          <PrimaryText
            size="xs"
            mt={spacing.M.rem}
            type={'regular'}
            color={color.light.textDisabled}
          >
            {disclaimer ?? getGenericDisclaimer()}
          </PrimaryText>
        </>
      )}
    </Flex>
  );
};

export default PaywallCheckoutLeftBanner;
