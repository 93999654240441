import { CurrentProfileApiResponse } from 'api/api.types';
import { TOASTS_CONTROLLER } from 'hooks/toast/useToastsController';
import Cookie from 'js-cookie';
import { COOKIE_ANALYTICS_TOAST_ENABLED } from 'utils/constants';
import { subscriptionTypeToString } from 'utils/global';
import * as amplitude from '@amplitude/analytics-browser';

class Analytics {
  private static instance: Analytics;
  private static isInitiated = false;
  private static amplitudeInstances: Record<
    string,
    amplitude.Types.BrowserClient
  > = {};
  /**
   * This boolean is true if we are running the site in a dev environment.
   */
  private static isDevEnv =
    !!process.env.NEXT_PUBLIC_DEV_ENV ||
    !!process.env.NEXT_PUBLIC_STAGING_BANNER ||
    !!process.env.NEXT_PUBLIC_STAGE_API ||
    process.env.NODE_ENV !== 'production';
  /**
   * This boolean is true if the current user (SI) is a developer
   * (i.e. @deepstash.com account)
   */
  private static isDevUser = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance = (): Analytics => {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  };

  /**
   * Init Amplitude Instance, should be called once on app start
   */
  public initAmplitude = ({
    isDev,
  }: {
    /**
     * Send as true if we want to log events to our dev playground
     */
    isDev?: boolean;
  }) => {
    if (process.env.NEXT_PUBLIC_LIGHTHOUSE_ENV) {
      // Don't initiate Amplitude during automated testing
      Analytics.amplitudeInstances['web'] = amplitude.createInstance();
      Analytics.amplitudeInstances['web']?.init('');
      Analytics.amplitudeInstances['mobile'] = amplitude.createInstance();
      Analytics.amplitudeInstances['mobile']?.init('');
      Analytics.isInitiated = true;
      return;
    }

    if (!Analytics.isDevUser) {
      // If we previously set isDevUser as true, it means it's been initiated.
      // We set isDevUser true only if the user is a developer or the email
      // ends in @deepstash.com
      // After that, we pick the right key if we're in a development env, or
      // if the user is a dev.
      Analytics.isDevUser = !!isDev;
      Analytics.amplitudeInstances['web'] = amplitude.createInstance();

      Analytics.amplitudeInstances['web']?.init(
        process.env.AMPLITUDE_API_KEY_WEB ?? '',
        '',
        {
          instanceName: 'web',
          // serverUrl: 'api.deepstash.com/v2/event/amp/',
          defaultTracking: false,
        },
      );
      Analytics.amplitudeInstances['mobile'] = amplitude.createInstance();
      Analytics.amplitudeInstances['mobile']?.init(
        process.env.AMPLITUDE_API_KEY_MOBILE ?? '',
        '',
        {
          instanceName: 'mobile',
          // serverUrl: 'api.deepstash.com/v2/event/amp/',
          defaultTracking: false,
        },
      );
      Analytics.isInitiated = true;
      console.log('[Amplitude] initAmplitude');
    }
  };

  public setUserEmail = ({ email }: { email: string }) => {
    const isDev = email.endsWith('@deepstash.com');

    if (!Analytics.isInitiated || (!Analytics.isDevEnv && isDev)) {
      this.initAmplitude({
        isDev,
      });
    }

    Analytics.amplitudeInstances['web']?.setUserId(email?.toLowerCase());
    Analytics.amplitudeInstances['mobile']?.setUserId(email?.toLowerCase());
    console.log('[Amplitude] setUserEmailAsId');
  };

  public setUserIdAndProperties = ({
    profile,
  }: {
    profile: CurrentProfileApiResponse;
  }) => {
    const isDev =
      profile.user_type === 'developer' ||
      profile.email.endsWith('@deepstash.com');

    if (!Analytics.isInitiated || (!Analytics.isDevEnv && isDev)) {
      this.initAmplitude({
        isDev,
      });
    }

    // Set user's id and properties if logged-in
    this.setUserId({ userId: profile?.email?.toLowerCase() });
    this.setUserProperties({
      created_at: profile.created_at,
      insight_count: profile.insight_count,
      stashes_count: profile.stashes_count,
      current_streak: profile.current_streak,
      longest_streak: profile.longest_streak,
      daily_goal: profile.daily_reading_goal,
      following_count: (profile.following ?? []).length,
      followers_count: (profile.followers ?? []).length,
      twitter_username: profile.twitter_username ?? '',
      followed_hashtags: profile.followed_hashtags ?? [],
      payment_plan: subscriptionTypeToString({
        subscriptionType: profile.subscription_type,
      }),
    });

    // User Properties that will NOT change, using setOnce
    const identify = new amplitude.Identify();
    identify.setOnce('web_first_version', process.env.WEBSITE_VERSION ?? '0.0');
    Analytics.amplitudeInstances['web']?.identify(identify);
    Analytics.amplitudeInstances['mobile']?.identify(identify);
  };

  /**
   * Set user's id, should be called on user login
   */
  private setUserId = ({ userId }: { userId: string }) => {
    Analytics.amplitudeInstances['web']?.setUserId(userId);
    Analytics.amplitudeInstances['mobile']?.setUserId(userId);
    console.log('[Amplitude] setUserId');
  };

  /** Set user's properties as object, should be called after user has an id */
  private setUserProperties = (
    userProperties: Partial<CurrentProfileApiResponse> & Record<string, any>,
  ) => {
    const identifyEvent = new amplitude.Identify();
    Object.keys(userProperties).forEach(key => {
      identifyEvent.set(key, userProperties[key]);
    });
    identifyEvent.set('web-version', process.env.WEBSITE_VERSION ?? '');
    Analytics.amplitudeInstances['web']?.identify(identifyEvent);
    Analytics.amplitudeInstances['mobile']?.identify(identifyEvent);
    console.log('[Amplitude] setUserProperties');
  };

  /** Called on logout */
  public setAnonymousUser = () => {
    const shouldSwitchApiKey = !Analytics.isDevEnv && Analytics.isDevUser;
    Analytics.isDevUser = false;
    if (!Analytics.isInitiated || shouldSwitchApiKey) this.initAmplitude({});

    Analytics.amplitudeInstances['web']?.reset();
    Analytics.amplitudeInstances['mobile']?.reset();
    console.log('[Amplitude] setAnonymousUser');
  };

  /** Called when setting a user prop */
  public setIdentifyUserProp = ({
    propName,
    propValue,
  }: {
    propName: string;
    propValue: string;
  }) => {
    if (!Analytics.isInitiated) {
      this.initAmplitude({});
    }
    const identify = new amplitude.Identify();
    identify.set(propName, propValue);
    Analytics.amplitudeInstances['web']?.identify(identify);
    Analytics.amplitudeInstances['mobile']?.identify(identify);
    console.log('[Amplitude] setIdentifyProp');
  };

  /** Log an event */
  public logEvent = ({
    eventName,
    properties,
    platforms,
  }: {
    eventName: string;
    properties?: Record<string, any>;
    platforms: ('amplitude' | 'amplitude-mobile' | 'firebase')[];
  }) => {
    platforms.forEach(platform => {
      switch (platform) {
        case 'amplitude': {
          if (!Analytics.isInitiated) this.initAmplitude({});
          Analytics.amplitudeInstances['web']?.logEvent(eventName, properties);
          break;
        }
        case 'amplitude-mobile': {
          if (!Analytics.isInitiated) this.initAmplitude({});
          Analytics.amplitudeInstances['mobile']?.logEvent(
            eventName,
            properties,
          );
          break;
        }
        default:
          break;
      }
    });

    const cookieAnalyticsToastEnabled = Cookie.get(
      COOKIE_ANALYTICS_TOAST_ENABLED,
    );
    const isAnalyticsToastEnabled = cookieAnalyticsToastEnabled
      ? cookieAnalyticsToastEnabled.toLowerCase() == 'true'
      : false;

    if (isAnalyticsToastEnabled) {
      TOASTS_CONTROLLER.showToast?.({
        title: eventName,
        description: JSON.stringify(properties) || '',
      });
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(
        `[Analytics] ${eventName} \n Props: ${JSON.stringify(
          properties,
        )} \n Platforms={${platforms}}`,
      );
    }
  };
}

export default Analytics.getInstance();
