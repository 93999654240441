import * as PurchaseApi from 'api/Purchases';
import { useEffect, useState } from 'react';
import {
  StripePricesData,
  StripeSubscriptionOrSetupIntent,
} from 'types/models';
import {
  CssFontSource,
  loadStripe,
  Stripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { color, spacing, typography } from 'deepstash-ui';
import { ProductType, RequestStatus, SubscriptionType } from 'types/types';
import { normalizeStripeSubscriptionIntent } from 'utils/normalizers/purchases';
import useStripePrices from 'hooks/purchases/useStripePrices';
import { isAxiosError } from 'api/apiRequest';
import { StripeProductPurchaseIntentApiResponse } from 'api/api.types';

const usePaymentInternals = ({
  selectedSubscriptionOrProductType,
  promoCodes,
  setInvalidPromo,
  promoCode,
  type = 'subscription',
}: {
  selectedSubscriptionOrProductType: SubscriptionType | ProductType;
  promoCodes: Record<SubscriptionType | ProductType, string | undefined>;
  setInvalidPromo: () => void;
  promoCode?: string;
  type?: 'subscription' | 'product';
}) => {
  const [stripeSubscriptionOrSetupIntent, setStripeSubscriptionOrSetupIntent] =
    useState<StripeSubscriptionOrSetupIntent>();
  const [stripeProductPurchaseIntent, setStripeProductPurchaseIntent] =
    useState<StripeProductPurchaseIntentApiResponse>();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const { stripePrices } = useStripePrices({
    promoCode,
    requestNsi: true,
  });

  // Callback to set the clientSecret and subscriptionId based on the chosen stripe subscription plan
  const chooseSubscriptionPlan = (
    setSubscriptionRequestStatus: (status: RequestStatus) => void,
    customToken?: string,
    stripePricesFromProps?: StripePricesData,
  ) => {
    const stripePricesData = stripePricesFromProps ?? stripePrices;
    if (!stripePricesData) {
      return;
    }
    setSubscriptionRequestStatus('loading');

    const priceId =
      type === 'subscription'
        ? stripePricesData.subscriptions[
            selectedSubscriptionOrProductType as SubscriptionType
          ]?.priceId
        : stripePricesData.products[
            selectedSubscriptionOrProductType as ProductType
          ]?.price_id;

    if (priceId) {
      if (type === 'subscription') {
        PurchaseApi.chooseStripeSubscription({
          priceId,
          promotionCode: promoCodes[selectedSubscriptionOrProductType],
          customToken: customToken,
        })
          .then(res => {
            setStripeSubscriptionOrSetupIntent(
              normalizeStripeSubscriptionIntent(res),
            );
            setSubscriptionRequestStatus('success');
          })
          .catch(error => {
            if (
              isAxiosError(error) &&
              // The backend will return 400 when the user tries to redeem a promo code if he has already redeemed one
              error.response?.status === 400 &&
              (promoCodes['1 MONTH'] ||
                promoCodes['1 YEAR'] ||
                promoCodes['3 MONTH'] ||
                promoCodes['6 MONTH'])
            ) {
              // The user tried to use an invalid promo code
              setInvalidPromo();
            }
            setSubscriptionRequestStatus('failure');
            setStripeSubscriptionOrSetupIntent(undefined);
            console.log(error.message);
          });
      } else {
        PurchaseApi.chooseStripeProduct({
          priceId,
          promotionCode: promoCodes[selectedSubscriptionOrProductType],
          customToken: customToken,
        })
          .then(res => {
            setStripeProductPurchaseIntent(res);
            setSubscriptionRequestStatus('success');
          })
          .catch(error => {
            if (
              isAxiosError(error) &&
              // The backend will return 400 when the user tries to redeem a promo code if he has already redeemed one
              error.response?.status === 400 &&
              promoCodes['LIFETIME']
            ) {
              // The user tried to use an invalid promo code
              setInvalidPromo();
            }
            setSubscriptionRequestStatus('failure');
            setStripeSubscriptionOrSetupIntent(undefined);
            console.log(error.message);
          });
      }
    }
  };

  useEffect(() => {
    if (stripePrices?.publishableKey) {
      loadStripe(stripePrices?.publishableKey, {
        apiVersion: '2020-08-27',
      })
        .then(res => setStripe(res))
        .catch(err => {
          console.log(err);
        });
    }
  }, [stripePrices?.publishableKey]);

  // this is used for styling the ElementPayment component from stripe
  const elementsOptions: StripeElementsOptions = {
    clientSecret:
      type === 'product'
        ? stripeProductPurchaseIntent?.payment_intent_client_secret ?? ''
        : stripeSubscriptionOrSetupIntent?.clientSecret ?? '',
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css?family=Source+Sans+Pro:bold, boldItalic, black, blackItalic, italic, regular',
        fontFamily: "'Source Sans Pro', sans-serif",
      } as CssFontSource,
    ],
    appearance: {
      theme: 'none',
      variables: {
        borderRadius: spacing.XXXL.rem,
        spacingGridRow: '0.5rem',
        spacingGridColumn: '0.5rem',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontSizeBase: '1rem',
        fontSize3Xs: '0.9rem',
        colorText: color.light.text,
        colorTextPlaceholder: color.light.textDisabled,
        colorTextSecondary: color.light.textSecondary,
      },
      rules: {
        '.Label': {
          marginBottom: '2',
          fontSize: '1rem',
          color: color.light.textSecondary,
        },
        '.Input': {
          marginTop: '0rem',
          marginBottom: '0rem',
          border: '1px solid',
          outline: 'none',
          paddingLeft: spacing.S.rem,
          paddingRight: spacing.S.rem,
          paddingTop: spacing.S.rem,
          paddingBottom: spacing.S.rem,
          borderColor: color.light.textDisabled,
          focusOutline: color.light.textSecondary,
          textRendering: 'optimizeLegibility',
          transition: 'none',
          ...typography.primaryTextFonts.medium.regular,
        },
        '.Input:focus': {
          border: '2px solid',
          outline: 'none',
          borderColor: color.light.pro.primary,
          focusOutline: color.light.textSecondary,
          paddingLeft: spacing.toRem(spacing.S.unit - 1),
          paddingRight: spacing.toRem(spacing.S.unit - 1),
          paddingTop: spacing.toRem(spacing.S.unit - 1),
          paddingBottom: spacing.toRem(spacing.S.unit - 1),
        },
        '.TermsText': {
          color: color.light.textSecondary,
        },
      },
    },
  };
  /**
   * Function that deletes the data
   * Used when closing the components with Stripe `Element`
   * Without deleting the data, the clientSecret won't change
   */
  const deleteStripeSubscriptionIntent = () => {
    setStripeSubscriptionOrSetupIntent(undefined);
  };

  return {
    clientSecret:
      type === 'product'
        ? stripeProductPurchaseIntent?.payment_intent_client_secret ?? ''
        : stripeSubscriptionOrSetupIntent?.clientSecret ?? '',
    subscriptionId: stripeSubscriptionOrSetupIntent?.subscriptionId ?? '',
    stripe,
    elementsOptions,
    chooseSubscriptionPlan,
    deleteStripeSubscriptionIntent,
  };
};

export default usePaymentInternals;
