import { useEffect } from 'react';
import jsCookie from 'js-cookie';
import { useRouter } from 'next/router';
import { Analytics, Events } from 'services/analytics';
import { clearUrlParams } from 'utils/global';
import useLocation from './useLocation';

const useUtmCampaign = () => {
  const router = useRouter();
  const location = useLocation();
  const { utm_campaign, utm_source } = router.query;

  useEffect(() => {
    if (utm_campaign) {
      jsCookie.set('utm_campaign', utm_campaign, {
        expires: 1,
      });
      // Don't spam the event for web funnel users
      if (location !== 'growth-plan') {
        Analytics.logEvent({
          eventName: Events.consumption.webOpenFromLink,
          properties: {
            location: location ?? '/',
            asPath: router.asPath,
            ...router.query,
          },
          platforms: ['amplitude-mobile'],
        });
        clearUrlParams({
          params: ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content'],
          router,
        });
      }
    }
  }, [utm_campaign]);

  useEffect(() => {
    if (utm_source) {
      jsCookie.set('utm_source', utm_source, {
        expires: 1,
      });
    }
  }, [utm_source]);

  return {
    utmCampaign: jsCookie.get('utm_campaign'),
    utmSource: jsCookie.get('utm_source'),
  };
};

export default useUtmCampaign;
