import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, SetupIntent, StripeError } from '@stripe/stripe-js';
import useLastLocation from 'hooks/useLastLocation';
import useUtmCampaign from 'hooks/useUtmCampaign';
import useProfile from 'providers/hooks/useProfile';
import { useEffect, useState } from 'react';
import { PaywallAnalyticsLocation } from 'types/enums';
import { FreeTrialData } from 'types/models';
import { PurchaseType, RequestStatus } from 'types/types';

const usePaymentCheckoutInternals = ({
  clientSecret,
  subscriptionId,
  productType,
  nameOnCard,
  isFromSetup,
  freeTrial,
  priceAmount,
  priceCurrency = 'usd',
  extraRequestParam,
  type,
}: {
  clientSecret: string;
  subscriptionId?: string;
  productType?: string;
  nameOnCard: string;
  isFromSetup: boolean;
  freeTrial?: FreeTrialData;
  location?: PaywallAnalyticsLocation | string;
  priceAmount: number;
  priceCurrency?: string;
  extraRequestParam?: string;
  type: PurchaseType;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { profile } = useProfile();
  const { lastLocation } = useLastLocation();
  const { utmCampaign } = useUtmCampaign();

  const [paymentStatus, setPaymentStatus] =
    useState<{ requestStatus: RequestStatus; error: string }>();

  const onPaymentIntentStatusRetrieved = (
    status?: SetupIntent.Status | PaymentIntent.Status,
  ) => {
    switch (status) {
      case 'succeeded':
        // Payment succeeded!
        setPaymentStatus({ requestStatus: 'success', error: '' });
        break;
      case 'processing':
        // Your payment is processing.
        break;
      case 'requires_payment_method':
        // Your payment was not successful, please try again.
        break;
      default:
        // Something went wrong.
        break;
    }
  };

  useEffect(() => {
    if (!stripe || !clientSecret || !elements) {
      return;
    }
    if (!freeTrial) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) =>
          onPaymentIntentStatusRetrieved(paymentIntent?.status),
        )
        .catch(console.error);
    } else {
      // The intent is a setup intent
      stripe
        .retrieveSetupIntent(clientSecret)
        .then(({ setupIntent }) =>
          onPaymentIntentStatusRetrieved(setupIntent?.status),
        )
        .catch(console.error);
    }
  }, [stripe, elements, freeTrial]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentStatus({ requestStatus: 'loading', error: '' });

    let error: StripeError | undefined = undefined;

    // TODO Remove product from free trial branch - N/A.
    if (freeTrial) {
      const stripeResponse = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: nameOnCard,
            },
          },
          return_url: !!profile
            ? `${
                window?.location?.origin ?? 'https://deepstash.com'
                // TODO Replace with current page location
              }${'/'}?${
                type === 'product'
                  ? `product_type=${productType}`
                  : `subscription_id=${subscriptionId}`
              }&is_from_setup=${isFromSetup}&has_free_trial=true&free_trial_duration=${
                freeTrial?.duration
              }&price_currency=${priceCurrency}&price_amount=${priceAmount}&location=${lastLocation}${
                utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
              }${
                extraRequestParam
                  ? `&extra_request_param=${extraRequestParam}`
                  : ''
              }`
            : `${
                window?.location?.origin ?? 'https://deepstash.com'
              }/activate-account?${
                type === 'product'
                  ? `product_type=${productType}`
                  : `subscription_id=${subscriptionId}`
              }&price_currency=${priceCurrency}&price_amount=${priceAmount}&location=${lastLocation}${
                utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
              }${
                extraRequestParam
                  ? `&extra_request_param=${extraRequestParam}`
                  : ''
              }`,
        },
      });

      error = stripeResponse.error;
    } else {
      const stripeResponse = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: nameOnCard,
            },
          },

          // Make sure to change this to your payment completion page
          return_url: !!profile
            ? `${window?.location?.origin ?? 'https://deepstash.com'}${'/'}?${
                type === 'product'
                  ? `product_type=${productType}`
                  : `subscription_id=${subscriptionId}`
              }&is_from_setup=${isFromSetup}&price_currency=${priceCurrency}&price_amount=${priceAmount}&location=${lastLocation}${
                utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
              }${
                extraRequestParam
                  ? `&extra_request_param=${extraRequestParam}`
                  : ''
              }`
            : `${
                window?.location?.origin ?? 'https://deepstash.com'
              }/activate-account?${
                type === 'product'
                  ? `product_type=${productType}`
                  : `subscription_id=${subscriptionId}`
              }&price_currency=${priceCurrency}&price_amount=${priceAmount}&location=${lastLocation}${
                utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
              }${
                extraRequestParam
                  ? `&extra_request_param=${extraRequestParam}`
                  : ''
              }`,
        },
      });
      error = stripeResponse.error;
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      console.log('---', error.message);
      setPaymentStatus({
        requestStatus: 'failure',
        error: error?.message ?? 'Your card was declined!',
      });
    } else {
      // TODO This according to the comment above is unreachable code.
      setPaymentStatus({
        requestStatus: 'success',
        error: '',
      });
    }
  };
  return { handleSubmit, paymentStatus };
};

export default usePaymentCheckoutInternals;
