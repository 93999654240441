import {
  Flex,
  spacing,
  PrimaryText,
  color,
  useColorMode,
  StyleProps,
  TextInputProps,
} from 'deepstash-ui';
import React from 'react';
import { authType } from 'src/providers/nsi/NsiProviderTypes';
import pageStrings from 'utils/strings/pageStrings';
import AuthTextInput from './AuthTextInput';

interface AuthEmailTextInputProps {
  onEnterPressed: () => void;
  emailError?: string;
  email: string;
  onChange?: (val: string) => void;
  authModalType?: authType;
  hideLabel?: boolean;
  textInputProps?: TextInputProps;
}

const AuthEmailTextInput: React.FC<AuthEmailTextInputProps & StyleProps> = ({
  onEnterPressed,
  emailError,
  email,
  onChange,
  authModalType,
  hideLabel,
  textInputProps,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const label =
    authModalType === 'sign-in'
      ? pageStrings.authentication.emailOrUsername
      : pageStrings.authentication.email;

  return (
    <Flex flexDir={'column'} mt={spacing.M.rem} width="100%" {...props}>
      {!hideLabel && (
        <PrimaryText
          size="s"
          type="bold"
          ml={spacing.XXS.rem}
          color={color[colorMode].textSecondary}
        >
          {pageStrings.authentication.email}
        </PrimaryText>
      )}
      <AuthTextInput
        value={email}
        onChange={onChange}
        placeholder={label}
        type="text"
        isInvalid={(emailError?.length ?? 0) > 0}
        errorText={emailError}
        onEnterPressed={onEnterPressed}
        {...textInputProps}
      />
    </Flex>
  );
};

export default AuthEmailTextInput;
