import {
  ABOUT,
  ADD_SOURCE,
  ALL_COLLECTIONS,
  ARTICLE,
  AUTH,
  CHECKOUT,
  DRAFTS,
  EMAIL_SUBSCRIPTIONS,
  EXPLORE,
  FOR_TEAMS,
  GET_PRO,
  HOME,
  HOW_IT_WORKS,
  IDEA,
  INFLUENCER,
  INSIGHT,
  INVITE,
  LANDING,
  NOTIFCIATIONS,
  NOT_FOUND,
  PAYWALL,
  PERSONAL_INFORMATION,
  PREFERENCES,
  PROFILE,
  PROGRESS,
  SEARCH,
  SETTINGS,
  SETUP,
  SHARE,
  SI_MOBILE_BLOCKED,
  STASH,
  TOPIC,
  ONBOARDING,
  PRODUCT_HUNT,
  REDEEM,
  ACTIVATE_ACCOUNT,
  QUIZ_FUNNEL,
  SETUP_SIGN_UP,
  STUDIO,
} from 'types/pageTypes';

const pageStrings = {
  [ADD_SOURCE]: {
    addTitle: 'Add title...',
  },
  [ABOUT]: {
    title: 'About',
    pageDescription:
      'Deepstash is an app for building your personal knowledge library and showing what you know. It will help you discover ideas, learn from your peers and boost your career.',
  },
  [ACTIVATE_ACCOUNT]: {
    title: 'Next Steps',
  },
  [ALL_COLLECTIONS]: {
    title: 'All Collections',
    startReadingTabTitle: 'START READING',
    continueReadingTabTitle: 'CONTINUE READING',
    allReadReadingTabTitle: 'ALL READ',
  },
  [AUTH]: {
    facebookLogin: 'Continue with Facebook',
    googleLogin: 'Continue with Google',
    title: 'Stash your favourite ideas!',
    TOS1: 'By creating an account, you agree with our ',
    TOS2: 'Terms of Service',
    privacyLink: 'Privacy Policy',
    separator: '&',
    signUpLater: 'Sign Up Later',
    passwordTooLong: 'Password cannot exceed 64 characters.',
    invalidEmailMessage: 'Please provide a valid email.',
    password: 'Password',
    choosePassword: 'Choose a strong password',
    forgotPassword: 'Forgot password',
    email: 'Email',
    emailAndPassword: 'Email & Password',
    emailOrUsername: 'Email or username',
    dontHaveAnAccount: "Don't have an account?",
    haveAnAccount: 'Have an account already',
    tryForFree: 'Try for free',
    continue: 'Continue with email & password',
    createAccount: 'Create account',
    emailNotValid: 'Email is not valid!',
    passwordNotValid: 'Password must be between 4 and 128 characters!',
    passwordFieldEmpty: 'Password field is empty!',
  },
  [PROFILE]: {
    title: 'Saved Ideas',
    tabs: {
      ideas: 'Saved Articles',
      topics: 'Ideas',
      stashes: 'Stashes',
    },
    yourAccount: 'Your Account',
    yourPreferences: 'Your Preferences',
    you: 'You',
    header: {
      headerText: '’s Ideas',
      published: 'Published',
      saved: 'Saved Ideas',
      stashes: 'Stashes',
    },
    publishedIdeas: 'Published Ideas',
    stashedIdeas: 'Saved Ideas',
    blank: {
      titleLoggedOut: 'Your Saved Ideas Live Here',
      titleLoggedIn: 'No Saved Ideas',
      textLoggedOut:
        'You’ll find them easier, absorb & remember them faster!\n\nStash ideas from our curated articles on the Home feed.',
      textLoggedIn: 'Stash ideas from our curated articles on the Home feed.',
      loggedOutFooter: '... to get started',
      noSavedIdeas: 'You have no saved ideas',
    },
    orderByPicker: {
      modalTitle: 'Sort',
    },
    filterByTopic: {
      modalTitle: 'Sort by topic',
    },
    // [Like functionality]
    // to be deleted
    likedIdeas: {
      noIdeas: 'This user has no saved ideas',
    },
    profileSteps: {
      name: {
        title: 'Name & username',
        description: `It’s always nice to know each others’ name. Let the community know
        yours.`,
        callbackText: 'Set up name & username',
      },
      photo: {
        title: 'Profile Photo',
        description: `Your profile picture gives a glimpse into who you are. Add a photo of
        yourself.`,
        callbackText: 'Set up profile photo',
      },
      bio: {
        title: 'Bio',
        description: `Let the community know what you like and do. You can link to @stashers or #topics`,
        callbackText: 'Add bio',
      },
      location: {
        title: 'Location',
        description: `Your profile location is your place to express yourself and show the
        world who you are.`,
        callbackText: 'Add location',
      },
      website: {
        title: 'Website',
        description: `Let others know where they can get in touch with you or what's your
        personal website.`,
        callbackText: 'Add link',
      },
      linkedIn: {
        title: 'Connect LinkedIn',
        description: `Connect the LinkedIn account to share your knowledge with your
        professional network.`,
        callbackText: 'Connect LinkedIn',
      },
      twitter: {
        title: 'Connect Twitter',
        description: `Connect the Twitter account to see your connections’ interests in
        Deepstash.`,
        callbackText: 'Connect Twitter',
      },
    },
    paywalls: {
      savedIdeas: {
        title: 'Too many saved ideas',
        description:
          'Only the 10 latest are displayed. Go Pro for unlimited saves.',
      },
    },
  },
  [HOME]: {
    recommendedFeedSectionTitles: {
      collections: 'COLLECTIONS',
      topics: 'TOPICS YOU FOLLOW',
      discover: 'DISCOVER NEW IDEAS',
    },
    continueReadingCollections: {
      title: 'Continue reading your collections',
      description:
        'Pick up where you left off your collections with the mobile app.',
    },
    continueTomorrowCollections: {
      title: 'Continue tomorrow',
      description:
        'Read daily and get notified to continue reading with the mobile app.',
    },
    dailyStash: {
      enableNotifications: 'Enable Notifications & Don’t Miss It',
    },
    header: {
      emptyFeed: {
        intro: [
          'Here you will see new posts from people that you follow',
          ' Discover stashers to follow based on your interests.',
          '👇',
        ],
        button: 'FOLLOW ALL',
      },
      new: {
        greeting: ['Welcome'],
        message: [
          'Browse the ideas from articles matching your interests. Get started by saving your first ones.',
        ],
      },
      recent: {
        greeting: ['Welcome back', 'Good to see you', 'Hello again'],
        message: [
          'Get started by saving key takeaways from some of the best online sources.',
        ],
      },
      default: {
        greeting: [
          'Welcome back',
          'Hello',
          'Hi',
          'Greetings',
          'Howdy',
          'How are you',
          'Good day',
        ],
        message: [
          'We have quite a few recommendations based on your saved ideas.',
          'What a day to enjoy life and discover new ideas for your knowledge base.',
          'Find ideas that help you grow. We’ll resurface them to keep them top of mind.',
          'Ideas made for sharing. We have quite a few ready for you.',
        ],
      },
    },
  },
  [TOPIC]: {
    section: {
      stashes: 'Stashes',
      stories: 'Stories',
    },
    header: {
      onThisTopic: 'on this topic',
    },
    noContentMessage: 'This topic has no articles.',
  },
  [ARTICLE]: {
    contentNA: 'Article content not available',
    section: {
      similar: {
        title: 'Similar',
        subtitle: ' Stories',
      },
    },
    nextArticle: {
      title: 'Next Article',
    },
    noIdeas: {
      1: 'No Ideas.',
      2: 'Tap onto sections to get started',
    },
    suggestedInsights: {
      footer: {
        title: 'The End',
        message:
          'Congrats for getting here. Don’t forget to stash any ideas you may want to quickly return to.',
      },
    },
    suggestedInsight: {
      header: 'Original Section',
    },
    otherTakesDescription:
      'Curious about different takes? Check out our book page to explore multiple unique summaries written by Deepstash curators:',
  },
  [SEARCH]: {
    placeholder: 'Search...',
    noResults: {
      title: 'No ideas found',
      subtitle: 'Try again with a new expression!',
    },
    emptyScreen: {
      title: 'Anything good starts with a good question',
    },
    recentSearches: 'Recent Searches',
  },
  [PREFERENCES]: {
    title: 'Preferences',
    appearance: {
      title: 'APPEARANCE',
      dailyReadingGoalTitle: 'Set your daily reading goal',
      dailyReadingGoalSubtitle:
        'Most people usually read at least 5 ideas every day but we recommend at least 10 if you want to be the smartest in the room.',
      readingGoalBannerTitle: 'Daily reading goal banner',
      readingGoalBannerSubtitle:
        'Show a banner when you have reached your daily goal',
    },
    updateHashtags: {
      title: 'Topics you Follow',
      subtitle:
        'Follow the topics you’re interested in to get great content recommendations.',
    },
  },
  [PERSONAL_INFORMATION]: {
    title: 'Personal Information',
    username: 'Username',
    placeHolderUsername: 'username',
    firstName: 'First name',
    placeHolderFirstName: 'First Name',
    lastName: 'Last name',
    placeHolderLastName: 'Last Name',
    email: 'EMAIL',
    placeHolderEmail: 'Email',
    location: 'Location',
    placeHolderLocation: 'Pick a country',
    bio: 'Bio',
    placeHolderBio: 'Bio',
    website: 'Website',
    placeHolderWebsite: 'Website',
  },
  [INSIGHT]: {
    saveTooltip: {
      title: 'Stash this idea',
      subtitle: 'to remember it!',
    },
    lockOverlay: {
      subtitle: '... to see them all',
    },
    more: ' more',
  },
  [STASH]: {
    editName: 'Edit Name',
    emptyStashMessage: 'This stash contains no ideas',
    header: {
      savedToThisStash: 'saved to this stash',
    },
  },
  [DRAFTS]: {
    title: 'Drafts',
    idea: {
      contentPlaceholder: 'What do you want to share with the world today?',
      titlePlaceholder: 'Title of the idea',
      imageTitlePlaceholder: 'Optional image title',
    },
    quote: {
      contentPlaceholder: 'A smart quote that got you thinking...',
      authorPlaceholder: 'AUTHOR',
    },
    quickAdd: {
      max: 'Create idea from max 4 paragraphs',
      create1: 'Create idea from',
      create2: 'paragraph',
      select: 'Select a paragraph to get started',
    },
  },
  [PROGRESS]: {
    title: 'Progress',
    achievements: {
      1: "You've made 2 ideas this week. Keep it up!",
      2: 'You are in the top 5% of Deepstash Creators. ',
      3: 'You created your first idea. Congrats! ',
      4: "You've imported 1 URL this week. Kudos!",
      5: 'You are in the top 5% of Deepstash Collectors.',
      6: 'You imported your first link. Congrats!',
      7: 'You have reached 5 total created stashes.',
      8: "You've saved 2 ideas this week. Great job!",
      9: 'You are in the top 25% of Deepstash Stashers.',
      10: 'You have reached 10 total saved ideas.',
      11: "You've read 62 ideas this week. The more the better!",
      12: 'You are in the top 5% of Deepstash Readers.',
      13: 'You have reached 100 total read ideas.',
    },
  },
  [SETTINGS]: {
    title: 'Settings',
    tryDeepstashPRO: 'Try Deepstash Pro',
    manageOnDevice: 'Manage your subscription on your device.',
    tabs: {
      personalInformation: {
        title: 'Personal Information',
        description: 'Details about your name, profile & email.',
      },
      preferences: {
        title: 'Preferences',
        description:
          'Customize your experience with display options or a custom reading goal. ',
      },
      emails: {
        title: 'Emails',
        description: 'Choose which emails you would like to receive.',
      },
    },
  },
  [EMAIL_SUBSCRIPTIONS]: {
    title: 'Emails',
    weeklyProgress: 'Weekly Progress',
    weeklyProgressHelpText: 'Review of your reading habits and saved ideas',
    dailyRecomandations: 'Daily Recommendations',
    dailyRecomandationsHelpText: 'Recommended stories and impactful ideas',
    productAnnouncements: 'Product Announcements',
    productAnnouncementsHelpText:
      'New features to upgrade your Deepstash experience',
  },
  [NOT_FOUND]: {
    title: '404 Page not found',
    details:
      'The page you are looking for was moved, removed, renamed or may have never existed.',
  },
  [NOTIFCIATIONS]: {
    title: 'Notifications',
  },
  [SETUP]: {
    localize: {
      1: 'Show the world what you know!',
      2: 'Discover ideas, learn from your peers and boost your career! 🙌',
    },
    aboutYourself: {
      1: 'What best describes what you do?',
      2: 'Deepstash will recommend ideas relevant to your profession.',
    },
    readingGoal: {
      dailyReadingGoal: 'SET A DAILY READING GOAL',
      chooseHowMuch:
        "Choose how much you'd like to read every day. Don't worry, you can change this later in your profile.",
    },
    recommendedTopics: {
      followTopicsPart1: 'Follow the topics ',
      followTopicsPart2:
        'that you’re interested in so you’ll get great recommendations. Don’t worry, you can always configure these later.',
    },
    recommendedPeople: {
      ourCommunity: 'Our community is already creating inspiring ideas about ',
      followStashers: 'Follow a few of them and never miss a great idea!',
    },
    allDone: {
      whenYouSee: 'When you see an idea you like, ',
    },
    allDonePro: {
      welcome: 'Welcome onboard Deepstash Pro!',
      trialEndsOnDate: 'Your free trial ends',
      cancelAnytime: 'Cancel anytime before that from your account.',
    },
    continue: 'Continue',
    start: 'Start exploring',
    done: 'Done',
    skip: 'Skip',
    next: 'Next',
  },
  [PAYWALL]: {
    start7DayTrial: 'Start 7-day free trial',
    howYourTrialWorks: 'How your trial works',
    itsTimeTo: "It's time to",
    tryDeepstashPro: 'Try Deepstash Pro',
    tryDeepstashProFree: 'Try Deepstash Pro for free',
    lastChance: 'Last chance to redeem this offer!',
    getDeepstashPro: 'Get Deepstash Pro',
    continueForFree: 'Continue for free.',
    notSureYet: 'Not sure yet? You can decide later.',
    invalidPromoTitle: 'Invalid Promo Code',
    invalidPromoBody: 'This promo code is no longer valid',
    invalidPromoContinue: 'Continue Without Promo Code',
    invalidPromoExit: 'Close',
    loadingMessage: 'Loading... Do NOT close the page',
  },
  [SI_MOBILE_BLOCKED]: {
    pageTitle: 'Install the app & start your reading journey!',
    switchToMobile: 'Get the app to enjoy all the features of Deepstash.',
    openDeepstash: 'Open Deepstash',
  },
  [INFLUENCER]: {
    section2: {
      title: 'What can you do in Deepstash Pro?',
      card1: {
        title: 'SAVE IDEAS FOR LATER',
        description1: 'On or off the grid',
        description2:
          ", always make sure you have knowledge on your side and there's always something to read.",
      },
      card2: {
        title: 'DIVE INTO YOUR LIBRARY',
        description1: 'View and manage your entire ',
        description2: 'knowledge library',
        description3: ', with all your saved ideas & stashes.',
      },
      card3: {
        title: 'NURTURE A READING HABIT',
        description1:
          "Daily reminders to keep up your reading streak ensure you'll never miss a beat. ",
        description2: 'Can you get to 100 days?',
      },
    },
    section3: {
      title1: 'Start with ideas from the ',
      title2: 'you just ',
    },
    section4: {
      title: 'How your free Deepstash Pro trial works',
    },
    section5: {
      title: 'Join +2M other daily readers',
    },
    section6: {
      title: 'Ready to begin your stasher journey?',
    },
  },
  [CHECKOUT]: {
    title: 'Confirm subscription',
    orEnterPaymentDetails: 'Or enter payment details',
    cardInformation: 'Card information',
    nameOnCard: 'Name on card',
    country: 'Country',
  },
  [INVITE]: {
    mobileFallbackMessage:
      "Check out Deepstash, it's an app with short ideas from books, articles, podcasts and more. Give it a try and follow me...",
  },
  [SHARE]: {
    fallbackMessage: "I think you'll enjoy reading this...",
    modal: {
      header: "What's Deepstash?",
      byteSizedKnowledge: 'Byte-sized knowledge',
      ideas: 'Ideas from books, articles & podcasts.',
    },
    footer: {
      continueDiscussion: 'Continue the discussion on Deepstash.',
      easy: 'It takes just 2 mins to install and it’s free.',
      getFree: 'Get FREE Deepstash app',
      continue: 'Continue reading without the app',
    },
  },
  [IDEA]: {
    privateIdea: {
      title: 'Private idea',
      content:
        "This idea is private or it's still a draft. Go home to read more public content.",
      goHome: 'Go home',
    },
    nextIdea: 'Next Idea',
    sourceSubsectionHeaderText: 'FROM THE',
    quoteHeaderText: 'Quote by',
  },
  [HOW_IT_WORKS]: {
    splashscreen: {
      title: 'How It Works',
      subtitle: 'All the knowledge in your pocket.',
    },
    appShowcase: [
      {
        titles: [],
        subtitles: [],
      },
      {
        titles: [
          {
            title: 'A Knowledge Feed',
            postTitle: 'To Feed Your Interests',
          },
        ],
        subtitles: [
          'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
        ],
      },
      {
        titles: [
          {
            preTitle: 'Read',
            title: 'Like a Boss',
          },
        ],
        subtitles: [
          'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
        ],
      },
      {
        titles: [
          {
            preTitle: 'Discover',
            title: 'New Ideas',
          },
        ],
        subtitles: [
          'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
        ],
      },
      {
        titles: [
          {
            title: 'Save & Organize Ideas',
          },
        ],
        subtitles: [
          'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
        ],
      },
      {
        titles: [
          {
            title: 'Share & Discuss',
          },
        ],
        subtitles: [
          'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
        ],
      },
    ],
  },
  [FOR_TEAMS]: {
    splashscreen: {
      title: 'Deepstash for Work',
      subtitle: [
        'Your team is at the heart of your organization’s success.',
        ' Deepstash helps your team get ',
        'creative',
        ', ',
        'innovative',
        ' and ',
        'engaged',
        '.',
      ],
    },
    appShowcase: [
      {
        titles: [
          {
            preTitle: 'From Benefit',
            title: 'To Experience',
          },
        ],
        subtitles: [
          'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
        ],
      },
      {
        titles: [{ preTitle: 'Real', title: 'Engagement' }],
        subtitles: [
          'Share and discuss ideas. Create groups for project or teams. Publicly or privately, any conversation is possible.',
        ],
      },
      {
        titles: [{ preTitle: 'Tailored', title: 'Content' }],
        subtitles: [
          'Develop tailored learning experiences and measure them through your analytics dashboard & engagement metrics.',
        ],
      },
    ],
    collections: {
      preTitle: 'Collections to',
      title: 'Get You Inspired',
    },
    bottomSection: {
      benefits: [
        {
          title: '3M+ members',
          subtitle: 'A solution your team will use and love.',
        },
        {
          title: '200k ideas',
          subtitle: 'Get your team cracking at life and be productive at work.',
        },
        {
          title: '4.7',
          subtitle: 'Loved by (almost) everyone.',
        },
      ],
    },
    readyToChatSection: {
      title: 'Ready to Chat?',
      subtitle:
        'Let’s show you how you can use Deepstash to transform your workspace.',
    },
    cta: 'Book a Demo',
    form: {
      successMessage: 'Someone will contact you soon.',
      errorTryAgain: 'There was an error, please reload and try again.',
      labels: {
        fullName: {
          label: 'Full Name*',
          placeholder: 'Jane Doe',
        },
        workEmail: {
          label: 'Business Email*',
          placeholder: 'jane.doe@company.com',
        },
        phoneNumber: {
          label: 'Phone Number',
          placeholder: 'Your phone number',
        },
        country: {
          label: 'Country',
          placeholder: 'Country',
        },
        reason: {
          label: 'Why do you want Deepstash for Teams?*',
          placeholder: "I'm interested because...",
        },
      },
      errors: {
        missingName: 'Name is missing! Please add your name.',
        missingEmail: 'Email is missing! Please add your work email address.',
        missingCountry: 'Country is missing! Please pick a country.',
        missingPhone: 'Phone number is missing! Please add your phone number.',
        missingReason: 'A short reason helps us better understand your needs.',
        invalidEmail:
          'The email address does not look valid. Please try another one.',
        personalEmail:
          'The email address appears to be a personal one. Please use a business email address.',
      },
    },
  },
  [LANDING]: {
    splashscreen: {
      title: {
        question: 'Got 5 Minutes?',
        sentence: 'Plenty for ',
        sourceTypes: [
          'a Book.',
          'a Podcast.',
          'a Video.',
          'an Article.',
          'Anything.',
          'Being Smart.',
        ],
      },
      subtitle: [
        'Deepstash helps creative (',
        'but busy',
        ') folk unlock',
        'knowledge from ',
        'books',
        ', ',
        'articles',
        ' & ',
        'podcasts',
        '.',
      ],
    },
    splashscreenCta: 'Get the FREE App',
    sourcesHeader: {
      preTitle: 'Trending',
      title: 'Collections',
    },
    appBanner: {
      mainText: [
        'deep',
        'stash',
        ' is available on ',
        'iOS',
        ' and ',
        'Android',
      ],
    },
    topicsHeader: {
      preTitle: 'Ideas on',
      title: 'Everything',
    },
    benefitsHeader: {
      preTitle: 'Read & Learn',
      title: '20x Faster',
    },
    conceptExplanationTexts: [
      {
        headers: [
          {
            preTitle: 'Discover Your',
            title: 'Next Big Idea',
          },
        ],
        texts: [
          'Ideas come from books, articles, podcasts & more...',
          '',
          'We make it ',
          'easy',
          ' by picking the best ideas from a ',
          '200,000+ catalogue',
          ' on ',
          'thousands of topics',
          '.',
        ],
        boldIndexes: [1, 3, 5, 7],
      },
      {
        headers: [
          {
            preTitle: 'Learn Anything',
            title: 'Remember Everything',
          },
        ],
        texts: [
          'The idea format helps ',
          '3M+ people',
          ' remember the ideas that',
          ' help them grow.',
          '',
          'Join the community. ',
          'Save',
          ' and ',
          'remember',
          ' ideas. Share & discover.',
        ],
        boldIndexes: [1, 3, 6, 8],
      },
      {
        headers: [
          {
            preTitle: 'Get Creative &',
            title: 'Build a Reading Habit',
          },
        ],
        texts: [
          '"Creativity is just connecting the dots."',
          ' - ',
          'Steve Jobs',
          '',
          'With Deepstash, you will build ',
          'stronger reading habits',
          ' through ',
          'bite-sized recommendations',
          ' throughout the day.',
        ],
        boldIndexes: [2, 5, 7],
      },
    ],

    testimonialsHeader: {
      preTitle: 'Join ',
      title: '2 Million Stashers',
    },
  },
  [GET_PRO]: {
    splashscreen: {
      title: ['Read Like a Pro'],
      subtitle: [
        'Jump-start your ',
        'reading habits',
        ', gather your ',
        'knowledge',
        ', ',
        'remember what you read',
        ' and ',
        'stay ahead of the crowd',
        '!',
      ],
    },
    paymentCard: {
      payYearly: ['Pay ', 'yearly'],
      payMonthly: 'monthly',
    },
    faqHeader: {
      title: 'Pricing',
      postTitle: 'Frequently Asked Questions',
    },
    faq: [
      {
        question: '	How does the free 7-day trial work?',
        answer:
          "Start your free 7-day trial by signing up for a Deepstash Pro yearly plan. There’s no obligation to stay subscribed, and you'll only be charged on the day the free 7-day trial ends. You have access to all the Pro features during the trial.",
      },
      // {
      //   question: 'Can I use Deepstash for free?',
      //   answer:
      //     'Sure thing. While you are not taking advantage of the powerful Pro features you can still access all the content, follow your favorite creators and get daily reading recommendations.',
      // },
      {
        question: "What's included in the Pro plan?",
        answer:
          'With Pro, you get all of Deepstash amazing content, but your interaction with it is unrestrained. Save as many ideas as you want, in as many stashes you desire. You can also go offline and still have access to your content, while also listening to your ideas whenever, wherever.',
      },
      {
        question: 'Can I cancel at any time?',
        answer:
          'Yes. If you are on a trial and you cancel it, you won’t be charged. If you have an ongoing subscription, you will keep your benefits until the next billing date.',
      },
      {
        question: 'What if I want to use Deepstash for work?',
        answer:
          'With Deepstash for work, you and your team can turbo-charge your learning, create a reading habit, share and discuss the ideas you find in chat. Reach out to us if you need a team plan.',
      },
      {
        question: 'When is my Lifetime Pro subscription activated?',
        answer:
          'Due to the nature of payment processing, please allow up to 24h for your Lifetime Pro subscription to become active.',
      },
      {
        question: 'What if I have more questions?',
        answer:
          'Send us an email at hello@deepstash.com and we’ll help you out.',
      },
    ],
    conceptExplanationTexts: [
      {
        headers: [
          {
            preTitle: 'Explore the World’s',
            title: 'Best Ideas',
          },
        ],
        texts: [
          '200,000+ ideas',
          ' on pretty much any topic. Created by the smartest people around & well-organized so you can explore at will.',
        ],
        cardTexts: [
          {
            title: 'An Idea for Everything',
            description:
              "Explore the biggest library of insights. And we've infused it with powerful filtering tools so you can easily find what you need.",
          },
          {
            title: 'Powerful Saving & Organizational Tools',
            description:
              'Save ideas for later reading, for personalized stashes, or for remembering it later.',
          },
        ],
      },
      {
        headers: [
          {
            preTitle: 'Take Your Ideas',
            title: 'Anywhere',
          },
        ],
        texts: [
          'Organize your ideas & listen on the go. And with Pro, there are no limits.',
        ],
        cardTexts: [
          {
            title: 'Listen on the go',
            description: 'Just press play and we take care of the words. ',
          },
          {
            title: 'Never worry about spotty connections',
            description:
              'No Internet access? No problem. Within the mobile app, all your ideas are available, even when offline.',
          },
          {
            title: 'Get Organized with Stashes',
            description:
              'Ideas for your next work project? Quotes that inspire you? Put them in the right place so you never lose them.',
          },
        ],
      },
    ],
    phoneTexts: {
      knowledgeLibrary: 'Knowledge Library',
      personalGrowth: '# Personal Growth',
      career: '# Career',
      myStashes: 'My Stashes',
    },
  },
  [EXPLORE]: {
    anchorTitle: 'Explore',
    seeAllAnchorSubtitle: 'These are the most...',
    dailyPicks: 'Daily Picks',
  },
  [ONBOARDING]: {
    personalizedHi: {
      hi: 'Hi,',
      hello: 'Hello!',
      description1: 'You are joining 3M+ smart people to',
      description2: ' discover ',
      description3: 'new ideas,',
      description4: ' learn ',
      description5: 'new things,',
      description6: ' improve ',
      description7: 'yourself &',
      description8: ' inspire ',
      description9: 'others.',
    },
    achieveQuestion: {
      title: 'What do you wish to achieve?',
      subtitle: 'Let’s recommend you the best content to get you started.',
      achievement1: 'Improve\nMyself',
      achievement2: 'Read More\nin Less Time',
      achievement3: 'Manage My\nKnowledge',
      achievement4: 'Share and\nDiscuss Ideas',
      textInputPlaceholder: 'Something else...',
    },
    recommendedHashtags: {
      allTopics: 'All topics',
      recommendedForYou: 'Recommended for you',
      selected: 'Selected',
    },
    contentConcept: {
      titleIdea: 'Why Ideas?',
      titleSource: 'The Source',
      subtitleIdea1:
        'Ideas are trapped inside long-form articles or books. Once extracted, ideas capture the',
      subtitleIdea2: ' essence of knowledge',
      subtitleIdea3: ', making them the perfect form of content.',
      subtitleSource1:
        'Ideas come from a source, the same way a song belongs to an album. The source can be a ',
      subtitleSource2: 'book, article, podcast',
      subtitleSource3: ' or ',
      subtitleSource4: 'video',
      subtitleSource5: ' or even a ',
      subtitleSource6: 'shower thought',
      subtitleSource7: ' you had.',
      saveIdeasTitle: 'Would you like to save these?',
      saveIdeasSubtitle1: 'Save ideas to build your',
      saveIdeasSubtitle2: ' library',
      saveIdeasSubtitle3:
        '. This will become your go-to source of inspiration for your creativity.',
      saveButton: 'Save and continue',
      skipButton: 'Skip without saving',
    },
    notificationsHourPicker: {
      title: 'Fresh ideas, delivered when?',
      subtitle:
        'Ideas curated for you, delivered when you want them. And no worries, you can change always adjust the time you get them.',
    },
    notificationPermission: {
      title: 'Get a daily reminder to discover ideas',
      notificationTitle: 'Keep your brain sharp 🧠',
      notificationTimestamp: '2m ago',
      notificationSubtitle: 'How eating your veggies makes you focus better',
    },
    readingGoal: {
      title1: 'Read & learn\n',
      title2: '20x',
      title3: ' faster',
      firstBulletPoint1: '  Understand',
      firstBulletPoint2: ' more',
      secondBulletPoint1: '  Remember',
      secondBulletPoint2: ' everything',
      thirdBulletPoint1: '  Build your',
      thirdBulletPoint2: ' personal library of knowledge',
      forthBulletPoint1: '  Follow ',
      forthBulletPoint2: 'topics',
      forthBulletPoint3: ' and ',
      forthBulletPoint4: 'curators',
      withoutDeep: 'without\ndeep',
      withDeep: 'with\ndeep',
      stash: 'stash',
    },
    chooseTopics: {
      title: 'What are you interested in?',
      subtitle:
        'Select 3 or more topics. You will get tailored ideas, also you can add more topics anytime.',
    },
    searchingForTopics: {
      title: '💪 Great Picks!',
      subtitle1:
        "Hold on... we're browsing through 200,000+ ideas for the top picks on ",
      subtitle2: ' and ',
      subtitle3: ' others',
    },
    searchingForTopicsDone: {
      title: 'Picked ideas are ready',
      firstBulletPoint: '  Explore your first collections of ideas.',
      secondBulletPoint: '  Develop your reading habit.',
      thirdBulletPoint: '  Expand your knowledge!',
    },
    thirdStepTooltip: {
      saveAndContinue: 'Save and continue home',
      skipAndContinue: 'Continue home without saving',
      saveAndContinueMobile: 'Save and get the free app',
      skipAndContinueMobile: 'Continue without saving',
    },
  },
  [PRODUCT_HUNT]: {
    sourcesHeader: {
      preTitle: 'Product Hunt',
      title: 'Special Picks',
    },
  },
  [REDEEM]: {
    title: 'Redeem Code',
    subtitle: 'Enter a code to activate your Pro subscription.',
  },
  [QUIZ_FUNNEL]: {
    prices_title: 'Get Smarter Every Day',
    prices_subtitle: 'in just 5 minutes',
    social_proof: 'Join 2 million people growing every day',
    bad_benefit: ['Too busy', 'to read full books'],
    good_benefit: ['Get smarter', 'in just 5 mins'],
    limited_discount: '50% discount reserved for',
    trial_disclaimers: {
      '1 YEAR': [
        'You have 7 days until your first payment. Cancel anytime until then and you will not be charged. Once active, your subscription will automatically renew at the full price of ',
        ' at the end of your 1-year subscription term.',
      ],
      '1 MONTH': [
        'You have 7 days until your first payment. Cancel anytime until then and you will not be charged. Once active, your subscription will automatically renew at the full price of ',
        ' at the end of your 1-month subscription term.',
      ],
      '3 MONTH': [
        'You have 7 days until your first payment. Cancel anytime until then and you will not be charged. Once active, your subscription will automatically renew at the full price of ',
        ' at the end of your 3-month subscription term.',
      ],
      '6 MONTH': [
        'You have 7 days until your first payment. Cancel anytime until then and you will not be charged. Once active, your subscription will automatically renew at the full price of ',
        ' at the end of your 6-month subscription term.',
      ],
      discount: [
        'You have 7 days until your first payment. Cancel anytime until then and you will not be charged. Once active, your subscription will automatically renew at the full price of ',
        ' at the end of your subscription term.',
      ],
      LIFETIME: [
        'You have 7 days until your first payment. Cancel anytime until then and you will not be charged. This is a one-time purchase for the sum of ',
        ' and you will have permanent access to Deepstash Pro.',
      ],
    },
    disclaimers: {
      '1 YEAR': [
        'You already have the discount applied to this first subscription price. Your subscription will automatically renew at the full price of ',
        ' at the end of your 1-year subscription term.',
      ],
      '1 MONTH': [
        'You already have the discount applied to this first subscription price. Your subscription will automatically renew at the full price of ',
        ' at the end of your 1-month subscription term.',
      ],
      '3 MONTH': [
        'You already have the discount applied to this first subscription price. Your subscription will automatically renew at the full price of ',
        ' at the end of your 3-month subscription term.',
      ],
      '6 MONTH': [
        'You already have the discount applied to this first subscription price. Your subscription will automatically renew at the full price of ',
        ' at the end of your 6-month subscription term.',
      ],
      discount: [
        'You already have the discount applied to this first subscription price. Your subscription will automatically renew at the full price of ',
        ' at the end of your subscription term.',
      ],
      LIFETIME: [
        'This is a one-time purchase for the sum of ',
        ' and you will have permanent access to Deepstash Pro.',
      ],
    },
  },
  [SETUP_SIGN_UP]: {
    setupDescription: [
      'Our recommendations based on your goals & interests are ready, find them below.',
      'Every Deepstash Pro membership includes:',
    ],
    setupHeader: ['Custom Results', 'Ready for You'],
    recommendedCollections: ['Picked for You', 'Tailored Collections'],
    recommendedSources: ['Recommended Reads', 'Books, Podcasts & Articles'],
    pricesExplanation: [
      'Get full access for 7 days.',
      'Love it and keep investing in yourself for only ',
      ', or cancel before ',
      '  and you will ',
      'not',
      ' be charged.',
    ],
  },
  [STUDIO]: {
    imageGenerationLoadingTexts: [
      'Brewing creativity in the AI cauldron... 🧙‍♂️✨',
      'Summoning pixels from the digital realm... 🖥️🎨',
      'Tickling the binary dragons for inspiration... 🐉💡',
      'Whispering sweet nothings to the GPU... 🤫💻',
      'Loading pixels, one neuron at a time... 🧠🎲',
      'Stirring the digital paint with algorithmic grace... 🎨🔄',
      'Convincing electrons to pose as pixels... ⚡📸',
      'Tick-tock, pixels on the clock... ⏰🎨',
      'Channeling the inner muse of the silicon sprites... 💻🧚',
      'Assembling a symphony of code and colors... 🎶🌈',
    ],
  },
};

export default pageStrings;
