import {
  color,
  spacing,
  TextInput,
  TextInputProps,
  useColorMode,
} from 'deepstash-ui';
import React, { useCallback } from 'react';

interface AuthTextInputProps {
  isInvalid: boolean;
  onEnterPressed: () => void;
}

const AuthTextInput: React.FC<AuthTextInputProps & TextInputProps> = ({
  isInvalid,
  onEnterPressed,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const detectEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onEnterPressed();
      }
    },
    [onEnterPressed],
  );

  const textInputProps: TextInputProps = {
    bgColor: color[colorMode].surface,
    borderRadius: spacing.XXXL.rem,
    px: spacing.M.rem,
    py: spacing.XS.rem,
    width: '100%',
    mb: isInvalid ? spacing.XS.rem : spacing.M.rem,
    onKeyPress: detectEnter,
    autoCapitalize: 'none',
    isInvalid: isInvalid,
    _hover: undefined,
    _active: undefined,
    _focus: { border: `2px solid ${color[colorMode].primary.default}` },
    outline: 'none',
  };

  return <TextInput {...textInputProps} {...props} />;
};

export default AuthTextInput;
