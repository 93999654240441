import { CurrentProfileApiResponse } from 'api/api.types';
import { authRequest } from 'api/apiRequest';
import * as AuthService from 'api/Auth';
import { fetchCurrent, followUser, updateInvitationStatus } from 'api/Profile';
import { useIsMobileView } from 'deepstash-ui';
import jsCookie from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import { useCallback } from 'react';
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import * as FacebookAnalytics from 'services/analytics/FacebookAnalytics';
import useActivity from 'src/providers/hooks/useActivity';
import useProfile from 'src/providers/hooks/useProfile';
import { Analytics, Events } from 'src/services/analytics';
import { AppleCallbackResponse, RequestStatus } from 'types';
import firebase from 'utils/analytics/firebase';
import { GENERIC_TAG_PATHNAME_PAGE } from 'utils/constants';
import { setSentryUserContext } from 'utils/log-utils';
import { normalizeProfile } from 'utils/normalizers/profile';
import { AuthRequestStatus } from '../../components/modals/auth-modal/auth-modal-content/AuthModalContent';
import useAuth from '../../providers/hooks/useAuth';

const getFeedUrlWithPromoCodeUrl = ({ promoCode }: { promoCode?: string }) => {
  if (promoCode) {
    return `/?promo_code=${promoCode}`;
  } else {
    return `/`;
  }
};

export interface SocialAuthCallbackProps {
  setRequestStatus: (val: AuthRequestStatus) => void;
  setIsSignup: (val: boolean) => void;
  discountCode?: string;
  shouldSkipOnboarding?: boolean;
  onAuthSuccess?: (profile: CurrentProfileApiResponse) => void;
}

const onSuccessfulAuthCallback = ({
  isOnGenericTag,
  discountCode,
  router,
}: {
  isOnGenericTag?: boolean;
  discountCode?: string;
  router: NextRouter;
  isNewAccount?: boolean;
}) => {
  // Adding promo_code as query param because it will be retrieved on index to check for a promo_code
  if (isOnGenericTag) {
    router.push(
      getFeedUrlWithPromoCodeUrl({
        promoCode: discountCode,
      }),
    );
  }
};

export type UserCredentials = {
  email: string;
  password: string;
};

/**
 * Hook that mutates the profile with the newly authenticated one
 */
const useLogin = () => {
  const { authDispatch } = useAuth();
  return useCallback(
    async (token: string, refresh_token: string, did_onboarding?: boolean) => {
      if (token === '') {
        return null;
      }

      try {
        const profileRes = await fetchCurrent(token);

        // Set the context on sentry
        setSentryUserContext(normalizeProfile(profileRes));

        if (did_onboarding !== undefined) {
          profileRes.did_onboarding = did_onboarding;
        }
        authDispatch({
          type: 'sign-in',
          payload: { token, refresh_token },
        });
        return profileRes;
      } catch (e) {
        alert("Couldn't sign in. Try again!");
        return null;
      }
    },
    [authDispatch],
  );
};

/**
 * A hook that generates the callback for the login service
 * @param setRequestStatus A callback to signal the loading state of the request
 * @param setLoginFailed A callback to signal the reason the login attempt has failed, if it has failed
 */
export const useOnLoginCallback = ({
  setRequestStatus,
  setAuthErrorMessage,
  discountCode,
  shouldRedirect = true,
  onAuthSuccess,
}: {
  setRequestStatus: (val: RequestStatus) => void;
  setAuthErrorMessage: (val: string) => void;
  discountCode?: string;
  /**
   * @default true
   */
  shouldRedirect?: boolean;
  onAuthSuccess?: (profile: CurrentProfileApiResponse) => void;
}) => {
  const login = useLogin();
  const { profileDispatch } = useProfile();
  const router = useRouter();
  const isOnGenericTag = router.pathname === GENERIC_TAG_PATHNAME_PAGE;

  const isMobileView = useIsMobileView();

  return useCallback(
    async (
      values: { email: string; password: string },
      trackLogin?: (email: string) => void,
    ) => {
      if (!values || !values.email || !values.password) return;

      setRequestStatus('loading');

      values.email = values.email.trim();

      try {
        const response = await authRequest(values.email, values.password);
        Analytics.logEvent({
          eventName: Events.configuration.loginAction,
          properties: {
            method: 'email',
            email: values.email,
          },
          platforms: ['amplitude'],
        });
        const profileRes = await login(
          response.access_token,
          response.refresh_token,
        );
        if (!profileRes) {
          return;
        }
        profileDispatch({
          type: 'mutate',
          payload: {
            profile: normalizeProfile(profileRes),
          },
        });
        setRequestStatus('success');

        // TODO Remove and move firebase to logEvent
        if (trackLogin) trackLogin(profileRes.email);
        firebase.analytics().setUserId(profileRes.id.toString());

        // Adding promo_code as query param because it will be retrieved on index to check for a promo_code
        if (onAuthSuccess) {
          onAuthSuccess(profileRes);
        } else if (!isMobileView && isOnGenericTag && shouldRedirect) {
          router.push(
            getFeedUrlWithPromoCodeUrl({
              promoCode: discountCode,
            }),
          );
        }
      } catch (error) {
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'email',
          },
          platforms: ['amplitude'],
        });
        setRequestStatus('failure');
        setAuthErrorMessage('Email or password are incorrect');
      }
    },
    [login, setRequestStatus],
  );
};

/**
 * Hook that returnes the callback needed to update a user activity accordingly if he joined Deepstash by an invite
 */
export const useInviteProcedureCallback = () => {
  const { activityDispatch, activity } = useActivity();
  return useCallback(
    //The new user
    (profile: CurrentProfileApiResponse) => {
      const id = jsCookie.get('inviteId');
      const hash = jsCookie.get('inviteHash');
      if (!id || !hash) {
        return;
      }
      updateInvitationStatus(profile.id.toString(), hash);
      jsCookie.set('followId', id, {
        expires: 1 / 480,
        sameSite: 'lax',
        secure:
          process.env.NODE_ENV === 'production' &&
          window.location.hostname !== 'localhost',
      });
      followUser(parseInt(id));
      if (activity) {
        activityDispatch({
          type: 'mutate',
          payload: {
            activity: {
              following: [...activity.following, id] as number[],
            },
          },
        });
      }

      jsCookie.remove('inviteId');
      jsCookie.remove('inviteHash');
    },
    [activityDispatch, activity],
  );
};
/**
 * A hook that generates the callback for the sign up service
 * @param setRequestStatus A callback to signal the loading state of the request
 * @param setLoginFailed A callback to signal the reason the login attempt has failed, if it has failed
 */
export const useOnSignUpCallback = ({
  setRequestStatus,
  setAuthErrorMessage,
  discountCode,
  onAuthSuccess,
  shouldSkipOnboarding,
}: {
  setRequestStatus: (val: RequestStatus) => void;
  setAuthErrorMessage: (val: string) => void;
  discountCode?: string;
  onAuthSuccess?: (profile: CurrentProfileApiResponse) => void;
  shouldSkipOnboarding?: boolean;
}) => {
  const router = useRouter();
  const isOnGenericTag = router.pathname === GENERIC_TAG_PATHNAME_PAGE;

  const onLogin = useOnLoginCallback({
    setRequestStatus,
    setAuthErrorMessage,
    shouldRedirect: false,
    onAuthSuccess,
  });

  return useCallback(
    async (values: UserCredentials) => {
      if (!values || !values.email || !values.password) {
        return;
      }
      setRequestStatus('loading');
      values.email = values.email.trim();
      const { email, password } = values;

      try {
        await AuthService.signUp({ email, password, shouldSkipOnboarding });
        Analytics.logEvent({
          eventName: Events.configuration.signUp,
          properties: {
            method: 'email',
            email: email,
          },
          platforms: ['amplitude'],
        });
        FacebookAnalytics.logCompleteRegistration({});

        await onLogin({ email, password }).then(() =>
          setRequestStatus('success'),
        );

        if (!onAuthSuccess) {
          onSuccessfulAuthCallback({
            isOnGenericTag: isOnGenericTag,
            discountCode: discountCode,
            isNewAccount: true,
            router,
          });
        }
      } catch (error) {
        setRequestStatus('failure');
        Analytics.logEvent({
          eventName: Events.stability.warningEvent,
          properties: {
            element: 'username',
            warning: 'username-exists',
          },
          platforms: ['amplitude'],
        });
        setAuthErrorMessage(
          (error as any)?.data?.error ??
            'An error has occurred! Please try again',
        );
      }
    },
    [onLogin, setRequestStatus],
  );
};

/**
 * A hook that generates the callback for the facebook auth service
 * @param setRequestStatus A callback to signal the loading state of the request
 * @param setIsSignup A callback to notice the parent component this was a signup
 */
export const useFacebookLoginCallback = ({
  setRequestStatus,
  setIsSignup,
  discountCode,
  shouldSkipOnboarding,
  onAuthSuccess,
}: SocialAuthCallbackProps) => {
  const inviteProcedure = useInviteProcedureCallback();
  const login = useLogin();
  const router = useRouter();
  const isOnGenericTag = router.pathname === GENERIC_TAG_PATHNAME_PAGE;

  return useCallback(
    async (info: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      if (!(info as ReactFacebookLoginInfo).accessToken) return;
      setRequestStatus('loading-facebook');
      const { id, accessToken } = info as any;
      localStorage.setItem('facebookLoginId', id);
      try {
        const fbauth = await AuthService.fbauth({
          token: accessToken.toString(),
          shouldSkipOnboarding,
        });
        const profile = await login(
          fbauth.token,
          fbauth.oauth_token.refresh_token,
          fbauth.is_new ? false : undefined,
        );
        if (fbauth.is_new) {
          Analytics.logEvent({
            eventName: Events.configuration.signUp,
            properties: {
              method: 'facebook',
            },
            platforms: ['amplitude'],
          });
          FacebookAnalytics.logCompleteRegistration({
            fb_login_id: id,
            fbp: jsCookie.get('_fbp'),
            fbc: jsCookie.get('_fbc'),
            client_user_agent: navigator?.userAgent,
          });

          setIsSignup(true);
          if (profile) {
            inviteProcedure(profile);
          }
        } else {
          setIsSignup(false);
          Analytics.logEvent({
            eventName: Events.configuration.loginAction,
            properties: {
              method: 'facebook',
            },
            platforms: ['amplitude'],
          });
        }
        setRequestStatus('success');

        if (onAuthSuccess && profile) {
          onAuthSuccess(profile);
        } else {
          onSuccessfulAuthCallback({
            isOnGenericTag: isOnGenericTag,
            discountCode: discountCode,
            isNewAccount: fbauth.is_new,
            router,
          });
        }
      } catch (error) {
        setRequestStatus('failure');
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'facebook',
          },
          platforms: ['amplitude'],
        });
      }
    },
    [
      isOnGenericTag,
      login,
      inviteProcedure,
      setRequestStatus,
      shouldSkipOnboarding,
      discountCode,
      onAuthSuccess,
    ],
  );
};

// TODO: need to error handle if fetchProfile inside loginUser fails
/**
 * A hook that generates the callback for the google auth service
 * @param setRequestStatus A callback to signal the loading state of the request
 *  @param setIsSignup A callback to notice the parent component this was a signup
 */
export const useGoogleLoginCallback = ({
  setRequestStatus,
  setIsSignup,
  discountCode,
  shouldSkipOnboarding,
  onAuthSuccess,
}: SocialAuthCallbackProps) => {
  const inviteProcedure = useInviteProcedureCallback();
  const login = useLogin();
  const router = useRouter();
  const isOnGenericTag = router.pathname === GENERIC_TAG_PATHNAME_PAGE;

  return useCallback(
    async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (!('tokenId' in response)) {
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'google',
          },
          platforms: ['amplitude'],
        });
        return;
      }
      try {
        setRequestStatus('loading-google');
        const gauth = await AuthService.gauth({
          token: response.tokenId.toString(),
          shouldSkipOnboarding,
        });
        const profile = await login(
          gauth.token,
          gauth.oauth_token.refresh_token,
          gauth.is_new ? false : undefined,
        );

        if (gauth.is_new) {
          Analytics.logEvent({
            eventName: Events.configuration.signUp,
            properties: {
              method: 'google',
            },
            platforms: ['amplitude'],
          });
          FacebookAnalytics.logCompleteRegistration({});

          setIsSignup(true);
          if (profile) {
            inviteProcedure(profile);
          }
        } else {
          setIsSignup(false);
          Analytics.logEvent({
            eventName: Events.configuration.loginAction,
            properties: {
              method: 'google',
            },
            platforms: ['amplitude'],
          });
        }
        setRequestStatus('success');

        if (onAuthSuccess && profile) {
          onAuthSuccess(profile);
        } else {
          onSuccessfulAuthCallback({
            isOnGenericTag: isOnGenericTag,
            discountCode: discountCode,
            isNewAccount: gauth.is_new,
            router,
          });
        }
      } catch (error) {
        setRequestStatus('failure');
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'google',
          },
          platforms: ['amplitude'],
        });
      }
    },
    [login, inviteProcedure, setRequestStatus, onAuthSuccess],
  );
};

/**
 * A hook that generates the callback for the google auth service
 * @param setRequestStatus A callback to signal the loading state of the request
 *  @param setIsSignup A callback to notice the parent component this was a signup
 */
export const useAppleLoginCallback = ({
  setRequestStatus,
  setIsSignup,
  discountCode,
  shouldSkipOnboarding,
  onAuthSuccess,
}: SocialAuthCallbackProps) => {
  const inviteProcedure = useInviteProcedureCallback();
  const login = useLogin();
  const router = useRouter();
  const isOnGenericTag = router.pathname === GENERIC_TAG_PATHNAME_PAGE;

  return useCallback(
    async (response: AppleCallbackResponse) => {
      if (!response?.authorization.id_token) {
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'apple',
          },
          platforms: ['amplitude'],
        });
        return;
      }
      try {
        setRequestStatus('loading-apple');
        const appleAuth = await AuthService.appleAuth({
          token: response.authorization.id_token.toString(),
          shouldSkipOnboarding,
        });
        const profile = await login(
          appleAuth.token,
          appleAuth.oauth_token.refresh_token,
          appleAuth.is_new ? false : undefined,
        );

        if (appleAuth.is_new) {
          Analytics.logEvent({
            eventName: Events.configuration.signUp,
            properties: {
              method: 'apple',
            },
            platforms: ['amplitude'],
          });
          FacebookAnalytics.logCompleteRegistration({});

          setIsSignup(true);
          if (profile) {
            inviteProcedure(profile);
          }
        } else {
          setIsSignup(false);

          Analytics.logEvent({
            eventName: Events.configuration.loginAction,
            properties: {
              method: 'apple',
            },
            platforms: ['amplitude'],
          });
        }
        setRequestStatus('success');

        if (onAuthSuccess && profile) {
          onAuthSuccess(profile);
        } else {
          onSuccessfulAuthCallback({
            isOnGenericTag: isOnGenericTag,
            discountCode: discountCode,
            isNewAccount: appleAuth.is_new,
            router,
          });
        }
      } catch (error) {
        setRequestStatus('failure');
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'apple',
          },
          platforms: ['amplitude'],
        });
      }
    },
    [login, inviteProcedure, setRequestStatus, onAuthSuccess],
  );
};

type GoogleOneTapResponse = {
  clientId: string;
  credential: string;
  select_by: string;
};
export const useGoogleOneTapLoginCallback = () => {
  const login = useLogin();

  const router = useRouter();
  const isOnGenericTag = router.pathname === GENERIC_TAG_PATHNAME_PAGE;

  return useCallback(
    async (response: GoogleOneTapResponse) => {
      if (!('credential' in response)) return;
      try {
        const gauth = await AuthService.gauth({ token: response.credential });
        if (gauth.is_new) {
          Analytics.logEvent({
            eventName: Events.configuration.signUp,
            properties: {
              method: 'google',
            },
            platforms: ['amplitude'],
          });
          FacebookAnalytics.logCompleteRegistration({});

          onSuccessfulAuthCallback({
            isOnGenericTag: isOnGenericTag,
            isNewAccount: gauth.is_new,
            router,
          });
        } else {
          Analytics.logEvent({
            eventName: Events.configuration.loginAction,
            properties: {
              method: 'google',
            },
            platforms: ['amplitude'],
          });
        }
        await login(
          gauth.token,
          gauth.oauth_token.refresh_token,
          gauth.is_new ? false : undefined,
        );
      } catch (error) {
        Analytics.logEvent({
          eventName: Events.stability.failedLogin,
          properties: {
            method: 'google',
          },
          platforms: ['amplitude'],
        });
      }
    },
    [login],
  );
};

export const onGoogleFailure = (error: any) => {
  console.error(error);
};

export const onGoogleAutoLoadFinished = (succesLogin: boolean) => {
  return succesLogin;
};
